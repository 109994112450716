import React, { useState } from "react";
import { graphql } from "gatsby";
import { get } from "lodash";
import Img from "gatsby-image";
// import contentParser from "gatsby-wpgraphql-inline-images";

import ReactHtmlParser from "react-html-parser";
import parse from "html-react-parser";

import SEO from "../components/MetaSeo";
import MiniHero from "../components/MiniHero";
import Layout from "../components/layout";
import SearchCourse from "../components/SearchCourse";
import LeadForm from "../components/LeadForm";
import LeadFormRD from "../components/LeadFormRD";

import VerticalWithAlternateImageAndText from "../components/treact/components/features/VerticalWithAlternateImageAndText";
import TwoColSingleFeatureWithStats from "../components/treact/components/features/TwoColSingleFeatureWithStats";
import TwoTrendingPreviewCardsWithImage from "../components/treact/components/cards/TwoTrendingPreviewCardsWithImage";
import SingleCol from "../components/treact/components/faqs/SingleCol";
import GetStartedLight from "../components/treact/components/cta/GetStartedLight";
import TwoColWithTwoHorizontalFeaturesAndButton from "../components/treact/components/features/TwoColWithTwoHorizontalFeaturesAndButton";
import WhatsappButton from "../components/WhatsappButton";

const Page = ({
  data: {
    wpPage: { title, content, slug, featuredImage, pageCountry }
  }
}) => {
  const [isOpen, setIsOpen] = useState(false);

  // const wordPressUrl = process.env.GATSBY_WP_URL;
  // const uploadsUrl = `${process.env.GATSBY_WP_URL}/wp-content/uploads/`;

  const cities = get(pageCountry, "cidadesImportantes", []).map(c => ({
    title: c.name,
    imageSrc: c.image.sourceUrl
  }));

  const faq = get(pageCountry, "visto", []).map(v => ({
    question: v.title,
    answer: v.description
  }));

  return (
    <Layout>
      <SEO title={title} pathname={slug} />
      <MiniHero
        name={title}
        img={get(featuredImage, "node.localFile")}
        size="big"
      />

      {/* <SearchCourse
        className="relative bg-transparent"
        style={{ top: "-5rem" }}
      /> */}

      <section
        className="container mx-auto  px-4"
        // style={{ top: "-5rem", position: "relative" }}
      >
        <div className="w-full h-full">
          {/* <h2 className="text-center sm:text-left text-black text-2xl md:text-4xl font-extrabold tracking-tight leading-tight mb-4">
            {title}
          </h2> */}

          {/* <img
            src={get(featuredImage, "node.sourceUrl")}
            className="object-cover object-center h-full w-full"
          /> */}

          {/* {get(featuredImage, "node.localFile") && (
            <Img
              className="absolute left-0 top-0 w-full h-full rounded z-0 object-cover"
              alt={slug}
              fluid={featuredImage.node.localFile.childImageSharp.fluid}
            />
          )} */}
        </div>

        <div className="w-full items-center mb-24">
          <div>
            <div
              className="text-center sm:text-left text-base md:text-lg text-blue-900 mb-5 content-page"
              dangerouslySetInnerHTML={{
                __html: get(pageCountry, "textoinicio")
              }}
            />

            <button
              type="button"
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded w-full sm:w-auto"
              onClick={() => setIsOpen(true)}
            >
              Fale com um consultor
            </button>
          </div>
        </div>
      </section>

      {/* <VerticalWithAlternateImageAndText title={title} cards={cards} /> */}

      {get(pageCountry, "educacaoSuperiorTexto") && (
        <TwoColWithTwoHorizontalFeaturesAndButton
          className="bg-red-100"
          heading={<span tw="text-primary-500">Educação Superior.</span>}
          description={get(pageCountry, "educacaoSuperiorTexto")}
          features={get(pageCountry, "educacaoSuperiorItems")}
        />
      )}

      <GetStartedLight
        heading="Se identificou com alguma das opções?"
        subheading={`Então fale com um Dreamer agora e começe a fazer seu planejamento para morar no ${title}!`}
        secondaryLinkText="Fale Agora"
        secondaryOnClick={() => setIsOpen(true)}
      />

      {get(pageCountry, "idiomasText") && (
        <div className="bg-blue-100">
          <section className="container mx-auto py-24 sm:px-8 xl:px-4">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-y-10 md:gap-y-32 gap-x-10 md:gap-x-24 items-center mb-24">
              <div>
                <h2 className="text-center sm:text-left text-black text-2xl md:text-4xl font-extrabold tracking-tight leading-tight mb-4">
                  Idioma
                </h2>

                <div
                  className="text-center sm:text-left text-base md:text-lg text-blue-900 mb-5"
                  dangerouslySetInnerHTML={{
                    __html: get(pageCountry, "idiomasText")
                  }}
                />

                <button
                  onClick={() => setIsOpen(true)}
                  className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded w-full sm:w-auto"
                >
                  Fale com um consultor
                </button>
              </div>
              <div className="w-full h-full">
                <img
                  src={get(pageCountry, "idiomasImage.sourceUrl")}
                  className="object-cover object-center h-full w-full"
                />
              </div>
            </div>
          </section>
        </div>
      )}

      <TwoTrendingPreviewCardsWithImage
        cards={cities}
        title="Cidades Importantes"
        className="bg-red-100"
      />

      {/* <GetStartedLight
        heading="Se identificou com alguma das opções?"
        subheading={`Então fale com um Dreamer agora e começe a fazer seu planejamento para morar no ${title}!`}
        secondaryLinkText="Fale Agora"
        secondaryOnClick={() => setIsOpen(true)}
      /> */}

      <TwoColSingleFeatureWithStats
        title="Informações Importantes"
        stats={get(pageCountry, "informacoesImportantes")}
      />

      <SingleCol faqs={faq} heading="Vistos" />

      {/* <LeadForm
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        values={{
          startDate: new Date().toISOString().split("T")[0],
          offering: { school: { country: { nameTranslation: title } } }
        }}
      /> */}

      <LeadFormRD
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        values={{
          startDate: new Date().toISOString().split("T")[0],
          offering: { school: { country: { nameTranslation: title } } }
        }}
      />

      {/* <WhatsappButton /> */}
    </Layout>
  );
};

export default Page;

export const pageQuery = graphql`
  query($id: String!) {
    wpPage(id: { eq: $id }) {
      title
      content
      slug
      pageCountry {
        iscountrytype
        textoinicio
        educacaoSuperiorTexto
        educacaoSuperiorItems {
          description
          name
        }
        idiomasText
        idiomasImage {
          sourceUrl
        }
        informacoesImportantes {
          key
          value
        }
        informacoesImportantesImg {
          sourceUrl
        }
        cidadesImportantes {
          name
          image {
            sourceUrl
          }
        }
        visto {
          description
          title
        }
      }
      featuredImage {
        node {
          sourceUrl
          localFile {
            childImageSharp {
              fluid(quality: 90, maxWidth: 1800) {
                ...GatsbyImageSharpFluid_withWebp_tracedSVG
              }
            }
          }
        }
      }
    }
  }
`;
