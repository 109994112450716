import React from "react";
import { useMount } from "react-use";

const LeadFormRD = ({ isOpen, setIsOpen, values }) => {
  useMount(() => {
    console.log("useMount");
    if (window.RDStationForms) {
      console.log("window.RDStationForms", window.RDStationForms);
      // Essa linha cria o elemento do formulário e faz o append do conteúdo na div abaixo.
      new window.RDStationForms(
        "formulario-conversao-pag-destino-0c15ea1fde84727455c8",
        "G-WS1XTZ63CR"
      ).createForm();
    }
  });
  return (
    <div className={isOpen ? "" : "invisible"}>
      <div className="flex items-center justify-center fixed left-0 bottom-0 w-full h-full bg-gray-800 bg-opacity-75  z-9999">
        <div className="bg-white rounded-lg sm:w-1/3">
          <div className="flex flex-col items-start p-4">
            <div className="flex items-center justify-between w-full">
              <div className="text-gray-900 font-medium text-lg">Simular</div>
              <div onClick={() => setIsOpen(false)}>
                <svg
                  className="ml-auto fill-current text-gray-700 w-6 h-6 cursor-pointer"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 18 18"
                >
                  <path d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z" />
                </svg>
              </div>
            </div>
            <hr />
            <div
              role="main"
              id="formulario-conversao-pag-destino-0c15ea1fde84727455c8"
            ></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LeadFormRD;
